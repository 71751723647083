<template>
    <div v-if="isVisible">
        <header-component
            v-bind:url="url"
            v-bind:status="status"
            v-bind:name="name"
        ></header-component>

        <div v-if="status === 'ERROR'">
            <div v-if="isInPdm">
                <div v-if="!hasValidArticleType" class="alert alert-danger">
                    Der Artikel hat keine gültige Artikel-Art im PDM. Diese muss
                    im abas erp gesetzt werden.
                </div>
                <div v-if="!isInSyncedPdmCategory" class="alert alert-danger">
                    Das Produkt im PDM ist nicht in einer Kategorie, die
                    synchronisiert wird.
                </div>
                <div v-if="!rangeItem" class="alert alert-danger">
                    Der Artikel ist nicht im Standardsortiment
                    {{ defaultRange.slug }} der Region {{ pdmRegion.language }}.
                </div>
                <div v-else>
                    <div
                        v-if="!isRangeCustomerReleaseInPast"
                        class="alert alert-danger"
                    >
                        Der Artikel ist im Sortiment
                        {{ defaultRange.slug }} noch nicht für Kunden
                        freigegeben.
                    </div>
                    <div if="isRangeSalesEndInPast" class="alert alert-danger">
                        Der Artikel hat im Sortiment
                        {{ defaultRange.slug }} ein Verkaufsende in der
                        Vergangenheit.
                    </div>
                </div>

                <div v-if="isPdmImageMissing" class="alert alert-danger">
                    Der Artikel hat kein Bild im PDM hinterlegt.
                </div>
            </div>

            <div v-else-if="isInWdm">
                <div v-if="!isInSyncedWdmCategory" class="alert alert-danger">
                    Das Produkt im WDM ist nicht in einer Kategorie, die
                    synchronisiert wird.
                </div>
                <div v-if="!isStartDateInPast" class="alert alert-danger">
                    Das Startdatum liegt in der Zukunft oder wurde noch nicht
                    gesetzt.
                </div>
                <div v-if="isEndDatePassed" class="alert alert-danger">
                    Das Enddatum ist überschritten.
                </div>
                <div v-if="isWdmImageMissing" class="alert alert-danger">
                    Der Artikel hat kein Bild im WDM hinterlegt.
                </div>
            </div>

            <div v-else>
                <div class="alert alert-danger">
                    Der Artikel ist weder im WDM noch im PDM angelegt.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import header from "./header.vue";
import { allFinished } from "../utils";

function isAnyIdInCategoryTree(categoryIds, category) {
    if (categoryIds.indexOf(category.id) !== -1) {
        return true;
    }
    for (const childCategory of category.children) {
        if (isAnyIdInCategoryTree(categoryIds, childCategory)) {
            return true;
        }
    }
    return false;
}

function isArticleNumberInCategoryTree(articleNumber, category) {
    for (const article of category.articles) {
        if (article.article_number === articleNumber) {
            return true;
        }
    }
    for (const child of category.children) {
        if (isArticleNumberInCategoryTree(articleNumber, child)) {
            return true;
        }
    }
    return false;
}

export default {
    components: {
        "header-component": header,
    },
    props: {
        syncedPdmCategoryIds: Array,
        syncedWdmCategoryIds: Array,
        pdmRegionLanguage: String,
        baseUrl: String,
        name: String,
    },
    computed: {
        isVisible() {
            let state = this.$store.state;
            return allFinished(state, [
                "abasArticle",
                "pdmArticle",
                "pdmProduct",
                "wdmArticle",
                "pdmCategories",
                "wdmCategories",
                "pdmRanges",
            ]);
        },

        status() {
            if (
                this.isInWdm &&
                this.isStartDateInPast &&
                !this.isEndDatePassed &&
                this.isInSyncedWdmCategory &&
                !this.isWdmImageMissing
            ) {
                return "OK";
            }
            if (
                this.isInPdm &&
                this.isRangeCustomerReleaseInPast &&
                !this.isRangeSalesEndInPast &&
                this.hasValidArticleType &&
                this.isInSyncedPdmCategory &&
                !this.isPdmImageMissing
            ) {
                return "OK";
            }
            return "ERROR";
        },
        hasValidArticleType() {
            if (this.pdmArticle != null) {
                return [
                    "regular_sales",
                    "special_edition",
                    "private_label",
                ].includes(this.pdmArticle.type_of_article);
            }
            return false;
        },
        abasArticle() {
            return this.$store.state.abasArticle.data;
        },
        pdmArticle() {
            return this.$store.state.pdmArticle.data;
        },
        pdmProduct() {
            return this.$store.state.pdmProduct.data;
        },
        wdmArticle() {
            return this.$store.state.wdmArticle.data;
        },

        syncedPdmCategories() {
            let categories = [];
            for (const category of this.$store.state.pdmCategories.data || []) {
                if (this.syncedPdmCategoryIds.indexOf(category.id) !== -1) {
                    categories.push(category);
                }
            }
            return categories;
        },
        syncedWdmCategories() {
            let categories = [];
            for (const category of this.$store.state.wdmCategories.data || []) {
                if (this.syncedWdmCategoryIds.indexOf(category.id) !== -1) {
                    categories.push(category);
                }
            }
            return categories;
        },
        url() {
            if (this.abasArticle == null) {
                return null;
            }
            return this.baseUrl + "/search?q=" + this.abasArticle.item_number;
        },

        isInPdm() {
            return this.pdmArticle != null;
        },
        pdmRegion() {
            if (!this.pdmRegions) {
                return null;
            }
            return this.pdmRegions.find(
                (region) => region.language == this.pdmRegionLanguage
            );
        },
        defaultRange() {
            if (this.pdmRegion == null) {
                return null;
            }
            let url = new URL(this.pdmRegion.default_range);
            const parts = url.pathname.split("/");
            const slug = parts[4];
            let range = this.pdmRanges[slug];
            return range;
        },
        rangeItem() {
            if (this.pdmProduct == null || this.defaultRange == null) {
                return null;
            }
            let item = this.defaultRange.items.find(
                (item) =>
                    item.article.item_number === this.pdmArticle.item_number
            );
            return item;
        },
        isRangeCustomerReleaseInPast() {
            if (this.rangeItem == null) {
                return false;
            }
            return (
                this.rangeItem.customer_release != null &&
                Date.parse(this.rangeItem.customer_release) <= Date.now()
            );
        },
        isRangeSalesEndInPast() {
            if (this.rangeItem == null) {
                return false;
            }
            return (
                this.rangeItem.sales_end != null &&
                Date.parse(this.rangeItem.sales_end) <= Date.now()
            );
        },
        pdmRanges() {
            return this.$store.state.pdmRanges.data.ranges;
        },
        pdmRegions() {
            return this.$store.state.pdmRanges.data.regions;
        },
        isInSyncedPdmCategory() {
            if (this.pdmProduct == null) {
                return false;
            }
            for (const category of this.syncedPdmCategories) {
                if (
                    isAnyIdInCategoryTree(this.pdmProduct.categories, category)
                ) {
                    return true;
                }
            }
            return false;
        },
        isInRegionDe() {
            if (this.abasArticle == null) {
                return false;
            }
            if (
                this.abasArticle.only_in_country !== "" &&
                this.abasArticle.only_in_country !== "DE"
            ) {
                return false;
            }
            return this.abasArticle.region_de;
        },
        isPdmImageMissing() {
            if (this.pdmProduct == null) {
                return false;
            }
            return !this.pdmProduct.image;
        },

        isInWdm() {
            return this.wdmArticle != null;
        },
        isStartDateInPast() {
            if (this.wdmArticle == null) {
                return false;
            }
            return (
                this.wdmArticle.start_date != null &&
                Date.parse(this.wdmArticle.start_date) <= Date.now()
            );
        },
        isEndDatePassed() {
            if (this.wdmArticle == null) {
                return false;
            }
            return (
                this.wdmArticle.end_date != null &&
                Date.parse(this.wdmArticle.end_date) < Date.now()
            );
        },
        isInSyncedWdmCategory() {
            if (this.wdmArticle == null) {
                return false;
            }
            for (const category of this.syncedWdmCategories) {
                if (
                    isArticleNumberInCategoryTree(
                        this.wdmArticle.article_number,
                        category
                    )
                ) {
                    return true;
                }
            }
            return false;
        },
        isWdmImageMissing() {
            if (this.wdmArticle == null) {
                return false;
            }
            return this.wdmArticle.image_url === null;
        },
    },
};
</script>
