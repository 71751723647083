<template>
    <div class="mb-4">
        <h3>
            {{ name }}
            <small>
                <a v-if="url" v-bind:href="url" target="_blank">
                    <i class="fas fa-external-link-alt"></i>
                </a>
            </small>
        </h3>

        <i v-if="status === 'loading'" class="fa fa-spinner fa-spin fa-5x"></i>

        <div v-if="status === 'error'" class="alert alert-danger">
            <span v-if="errorMessage !== ''">
                {{ errorMessage }}
            </span>
            <span v-else>
                Ein Fehler ist bei der Abfrage aufgetreten. Bitte probieren Sie
                es später noch einmal.
            </span>
        </div>

        <div v-if="status === 'not_found'" class="alert alert-warning">
            Der Artikel konnte nicht im {{ name }} gefunden werden.
            <span v-if="notFoundMessage">{{ notFoundMessage }}</span>
        </div>

        <div v-if="status === 'success' && data !== null">
            <slot></slot>

            <div class="card text-dark bg-light">
                <div
                    class="card-header"
                    role="button"
                    data-bs-toggle="collapse"
                    v-bind:href="'#' + prefix + '-data'"
                >
                    <span class="font-monospace">API Daten</span>
                    <i class="fas fa-chevron-down"></i>
                </div>
                <div v-bind:id="prefix + '-data'" class="collapse">
                    <div class="card-body">
                        <pre><code>{{ data }}</code></pre>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import slug from "slug";

export default {
    props: {
        data: Object,
        status: String,
        name: String,
        errorMessage: String,
        url: String,
        notFoundMessage: String,
    },
    computed: {
        prefix() {
            return slug(this.name);
        },
    },
};
</script>
