import Vue from "vue";
import Vuex from "vuex";
import actions from "./actions";
import mutations from "./mutations";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        articleNumber: "",
        exampleArticleNumbers: {
            10092: "regulärer Verkaufsartikel",
            10110: "alter Verkaufsartikel",
            "1AR01359": "OC Artikel",
            "1AR03419": "Werbematerial",
            "1AR00221": "altes Werbematerial",
            "1AR00116": "Rohmaterial",
            "1AR00149": "OC Artikel",
            "1AR01298": "abgelaufenes Infomaterial",
            V00019: "virtuelles Werbemittelpaket",
        },
        abasArticle: {
            status: "empty",
            errorMessage: "",
            data: null,
        },
        wdmArticle: {
            status: "empty",
            errorMessage: "",
            data: null,
        },
        pdmProduct: {
            status: "empty",
            errorMessage: "",
            data: null,
        },
        pdmArticle: {
            status: "empty",
            errorMessage: "",
            data: null,
        },
        pdmCategories: {
            status: "empty",
            errorMessage: "",
            data: null,
        },
        pdmTestPlan: {
            status: "empty",
            errorMessage: "",
            data: null,
        },
        wdmCategories: {
            status: "empty",
            errorMessage: "",
            data: null,
        },

        namespace: "pdmRanges",
        remoteData: {
            status: "empty",
            errorMessage: "",
            data: null,
        },
    },
    mutations,
    actions,
});

export default store;
