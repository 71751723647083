<template>
    <div v-if="isVisible">
        <header-component
            v-bind:url="url"
            v-bind:status="status"
            name="PDM Standard-Sortimente"
        ></header-component>
        <p>
            Der Artikel ist in den folgenden Regionen-Standard-Sortimenten
            vertreten. Darüberhinaus kann er noch in kundenspezifischen
            Sortimenten auftauchen, dazu bitte dem Link ins PDM folgen.
        </p>

        <table class="table">
            <thead>
                <tr>
                    <th>Sortiment</th>
                    <th>Für Alle</th>
                    <th>Für Kunden</th>
                    <th>Verkauf ab</th>
                    <th>Verkauf bis</th>
                    <th>Auslaufartikel</th>
                </tr>
            </thead>
            <tr v-for="(item, range_slug) in rangeItems" :key="range_slug">
                <td>{{ range_slug }}</td>
                <td>
                    {{ date(item.public_release) }}
                </td>
                <td>
                    {{ date(item.customer_release) }}
                </td>
                <td>
                    {{ date(item.sales_start) }}
                </td>
                <td>
                    {{ date(item.sales_end) }}
                </td>
                <td>
                    <i
                        class="fa fa-check text-danger"
                        v-if="item.is_discontinued"
                    ></i>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import header from "./header.vue";
import { allFinished } from "../utils";

export default {
    components: {
        "header-component": header,
    },
    methods: {
        date(f) {
            if (f) {
                let parsed = new Date(Date.parse(f));
                return parsed.toLocaleDateString(undefined, {
                    dateStyle: "short",
                });
            }
            return "-";
        },
    },
    computed: {
        isVisible() {
            let state = this.$store.state;
            return (
                allFinished(state, ["pdmArticle", "pdmRanges"]) &&
                this.pdmArticle !== null
            );
        },

        status() {
            if (!this.rangeItems) {
                return "ERROR";
            }
            return "OK";
        },

        pdmArticle() {
            return this.$store.state.pdmArticle.data;
        },
        rangeItems() {
            if (this.pdmArticle == null) {
                return null;
            }
            let ranges = {};
            for (let range of Object.values(this.pdmRanges)) {
                for (let item of range.items) {
                    if (
                        item.article.item_number == this.pdmArticle.item_number
                    ) {
                        ranges[range.slug] = item;
                        break;
                    }
                }
            }
            return ranges;
        },
        pdmRanges() {
            return this.$store.state.pdmRanges.data.ranges;
        },
        pdmRegions() {
            return this.$store.state.pdmRanges.data.regions;
        },
        url() {
            if (this.pdmArticle == null) {
                return null;
            }
            return (
                "https://proclima-pdm.moll-group.eu/article_ranges/?q=" +
                this.pdmArticle.item_number
            );
        },
    },
};
</script>
