<template>
    <div>
        <div class="row">
            <div class="col-sm-6">
                <search
                    label="Artikelnummer"
                    v-bind:articleNumber="$store.state.articleNumber"
                    v-on:search-article="onSearchArticle"
                    v-on:reset="onReset"
                ></search>
            </div>
            <div class="col-sm-6">
                <strong>Beispiel</strong>
                <ul>
                    <li
                        v-for="articleNumber of Object.keys(
                            $store.state.exampleArticleNumbers
                        )"
                    >
                        <a :href="'#' + articleNumber">
                            {{ articleNumber }} –
                            {{
                                $store.state.exampleArticleNumbers[
                                    articleNumber
                                ]
                            }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <div v-if="$store.state.articleNumber !== ''">
            <h2 class="mb-4">
                Ergebnis für Artikel
                <code>{{ $store.state.articleNumber }}</code>
            </h2>

            <div class="row">
                <div class="col-sm-6">
                    <einfach-packen></einfach-packen>
                    <retailerportal
                        :syncedPdmCategoryIds="[1, 652]"
                        :syncedWdmCategoryIds="[1]"
                        pdmRegionLanguage="de-de"
                        baseUrl="https://handel.proclima.de"
                        name="Händlerportal DE"
                    ></retailerportal>

                    <!-- we actually sync pdm category 4480 which is the translation of
                        4465 into french, but it has the same contents -->
                    <retailerportal
                        :syncedPdmCategoryIds="[4465]"
                        :syncedWdmCategoryIds="[106]"
                        pdmRegionLanguage="fr-fr"
                        baseUrl="https://distributeurs.proclima.info"
                        name="Händlerportal FR"
                    ></retailerportal>
                    <cms
                        pdmRegionLanguage="de-de"
                        searchUrl="https://de.proclima.com/suche?q="
                        name="de.proclima.com"
                    ></cms>
                    <cms
                        pdmRegionLanguage="en-xx"
                        searchUrl="https://proclima.com/search?q="
                        name="proclima.com"
                    ></cms>
                    <cms
                        pdmRegionLanguage="fr-fr"
                        searchUrl="https://fr.proclima.com/rechercher?q="
                        name="proclima.info"
                    ></cms>
                    <qstool></qstool>
                    <pdm-ranges></pdm-ranges>
                </div>

                <div class="col-sm-6">
                    <abas-article
                        v-bind:data="$store.state.abasArticle"
                    ></abas-article>

                    <wdm-article
                        v-bind:data="$store.state.wdmArticle"
                    ></wdm-article>

                    <pdm-product
                        v-bind:data="$store.state.pdmProduct"
                    ></pdm-product>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SearchForm from "./search_form.vue";
import AbasArticle from "./abas_article.vue";
import WdmArticle from "./wdm_article.vue";
import PdmProduct from "./pdm_product.vue";
import PdmRanges from "./pdm_ranges.vue";
import EinfachPacken from "./einfach_packen.vue";
import Retailerportal from "./retailerportal.vue";
import Cms from "./cms.vue";
import qstool from "./qstool.vue";

export default {
    components: {
        search: SearchForm,
        abasArticle: AbasArticle,
        wdmArticle: WdmArticle,
        pdmProduct: PdmProduct,
        pdmRanges: PdmRanges,
        einfachPacken: EinfachPacken,
        retailerportal: Retailerportal,
        qstool: qstool,
        cms: Cms,
    },
    methods: {
        onSearchArticle(articleNumber) {
            if (articleNumber !== "") {
                this.$store.dispatch("searchArticle", { articleNumber });
            } else {
                this.$store.dispatch("reset");
            }
        },
        onReset() {
            this.$store.dispatch("reset");
        },
    },
    created() {
        this.$store.dispatch("loadPdmRanges");
        this.$store.dispatch("loadWdmCategories");
        this.$store.dispatch("loadPdmCategories");
        var onHashChange = () => {
            if (window.location.hash.length > 1) {
                let articleNumber = window.location.hash.substr(1);
                if (articleNumber !== this.$store.state.articleNumber) {
                    this.$store.dispatch("searchArticle", { articleNumber });
                }
            } else {
                this.$store.dispatch("reset");
            }
        };
        window.addEventListener("hashchange", onHashChange);
        onHashChange();
    },
};
</script>
