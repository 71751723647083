<template>
    <div v-if="isVisible">
        <header-component
            v-bind:url="url"
            v-bind:status="status"
            v-bind:name="name"
        ></header-component>

        <div v-if="status === 'ERROR'">
            <div v-if="!rangeItem" class="alert alert-danger">
                Der Artikel ist nicht im Standardsortiment
                {{ defaultRange.slug }} der Region {{ pdmRegion.language }}.
            </div>
            <div v-else>
                <div
                    v-if="!isRangePublicReleaseInPast"
                    class="alert alert-danger"
                >
                    Der Artikel ist im Sortiment
                    {{ defaultRange.slug }} noch nicht für Kunden freigegeben.
                </div>
                <div if="isRangeSalesEndInPast" class="alert alert-danger">
                    Der Artikel hat im Sortiment
                    {{ defaultRange.slug }} ein Verkaufsende in der
                    Vergangenheit.
                </div>
            </div>
            <div v-if="isPdmImageMissing" class="alert alert-danger">
                Der Artikel hat kein Produkt-Bild im PDM hinterlegt.
            </div>
        </div>
    </div>
</template>

<script>
import header from "./header.vue";
import { allFinished } from "../utils";

export default {
    components: {
        "header-component": header,
    },
    props: {
        pdmRegionLanguage: String,
        searchUrl: String,
        name: String,
    },
    computed: {
        isVisible() {
            let state = this.$store.state;
            return allFinished(state, [
                "pdmArticle",
                "pdmProduct",
                "pdmCategories",
                "pdmRanges",
            ]);
        },

        status() {
            if (!this.pdmArticle) {
                return "IGNORE";
            }
            if (
                this.pdmProduct &&
                this.pdmArticle &&
                this.isRangePublicReleaseInPast &&
                !this.isRangeSalesEndInPast &&
                !this.isPdmImageMissing
            ) {
                return "OK";
            }
            return "ERROR";
        },
        pdmArticle() {
            return this.$store.state.pdmArticle.data;
        },
        pdmProduct() {
            return this.$store.state.pdmProduct.data;
        },
        url() {
            if (this.pdmArticle == null) {
                return null;
            }
            return this.searchUrl + this.pdmArticle.item_number;
        },
        pdmRegion() {
            if (!this.pdmRegions) {
                return null;
            }
            return this.pdmRegions.find(
                (region) => region.language == this.pdmRegionLanguage
            );
        },
        defaultRange() {
            if (this.pdmRegion == null) {
                return null;
            }
            let url = new URL(this.pdmRegion.default_range);
            const parts = url.pathname.split("/");
            const slug = parts[4];
            let range = this.pdmRanges[slug];
            return range;
        },
        rangeItem() {
            if (this.pdmProduct == null || this.defaultRange == null) {
                return null;
            }
            let item = this.defaultRange.items.find(
                (item) =>
                    item.article.item_number === this.pdmArticle.item_number
            );
            return item;
        },
        isRangePublicReleaseInPast() {
            if (this.rangeItem == null) {
                return false;
            }
            return (
                this.rangeItem.public_release != null &&
                Date.parse(this.rangeItem.public_release) <= Date.now()
            );
        },
        isRangeSalesEndInPast() {
            if (this.rangeItem == null) {
                return false;
            }
            return (
                this.rangeItem.sales_end != null &&
                Date.parse(this.rangeItem.sales_end) <= Date.now()
            );
        },
        pdmRanges() {
            return this.$store.state.pdmRanges.data.ranges;
        },
        pdmRegions() {
            return this.$store.state.pdmRanges.data.regions;
        },
        isPdmImageMissing() {
            if (this.pdmProduct == null) {
                return false;
            }
            return !this.pdmProduct.image;
        },
    },
};
</script>
