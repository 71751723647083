<template>
    <remoteData
        v-bind:data="data.data"
        v-bind:status="data.status"
        v-bind:errorMessage="data.errorMessage"
        v-bind:url="url"
        name="ABAS"
    >
        <div v-if="article !== null">
            <dl class="dl-horizontal">
                <dt>Name</dt>
                <dd>{{ article.name }}</dd>
                <dt>Produkt ID</dt>
                <dd>{{ article.product_id }}</dd>
                <dt>Suchwort</dt>
                <dd>{{ article.keyword }}</dd>
                <dt>Gewicht</dt>
                <dd>{{ article.weight }}</dd>
                <dt>Mischpreis</dt>
                <dd>{{ article.suggested_price }}</dd>
                <dt>Statistischer Wert</dt>
                <dd>{{ article.statistic_value }}</dd>
                <dt>Listenpreis</dt>
                <dd>{{ article.list_price }}</dd>
                <dt>Beschreibung</dt>
                <dd>{{ article.description }}</dd>
                <dt>Regionen</dt>
                <dd>{{ regions }}</dd>
                <dt>Preisgruppe</dt>
                <dd>{{ article.price_group_id }}</dd>
                <dt>Rabattgruppe</dt>
                <dd>{{ article.discount_group_id }}</dd>
            </dl>
        </div>
    </remoteData>
</template>

<script>
import RemoteData from "./remote_data.vue";

export default {
    props: {
        data: Object,
    },
    computed: {
        article() {
            return this.data.data;
        },
        regions() {
            if (!this.article) {
                return [];
            }
            let regions = [];
            for (let prop in this.article) {
                if (prop.startsWith("region_") && this.article[prop]) {
                    regions.push(prop.substring("region_".length));
                }
            }
            regions.sort();
            return regions.join(", ");
        },
        url() {
            if (!this.article) {
                return null;
            }
            return (
                "abasurl:<Teil>" +
                this.article.item_number +
                "<zeigen>?DDE=proclimaerp"
            );
        },
    },
    components: {
        remoteData: RemoteData,
    },
};
</script>
